import { keyframes } from 'styled-components';

// 2 sec pause
export const bounce = keyframes`
  0%, 6.6%, 26%, 33%, 100% {
    transform: translateY(0) rotate(45deg);
  }
  16.5% {
    transform: translateY(5px) rotate(45deg);
  }
  13.2% {
    transform: translateY(-16px) rotate(45deg);
  }
  19.8% {
    transform: translateY(-8px) rotate(45deg);
  }
`;
