import axios from 'axios';

interface IGetAds {
  workCityId: any;
  radius: any;
  query: any;
  pageNumber: number;
}

export async function getAds({
  workCityId,
  radius,
  query,
  pageNumber,
}: IGetAds) {
  try {
    const response = await axios({
      method: 'GET',
      url: 'https://live.auranest.com/sv-se/api/jobfeed/search?',
      params: {
        fWorkCityId: workCityId,
        fWorkCityRadius: radius,
        query: query,
        pageNumber: pageNumber,
        pageSize: 20,
        sSortOrder: 'desc',
        sSortField: 'publish_date',
      },
    });

    return response;
  } catch (error) {}
}
