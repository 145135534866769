import ale_emblem from '../images/municipality_logotypes/Ale Kommun/alevapen.png';
import alingsas_emblem from '../images/municipality_logotypes/Alingsås Kommun/alingsasvapen.png';
import alvesta_emblem from '../images/municipality_logotypes/Alvesta Kommun/alvestakommun.png';
import aneby_emblem from '../images/municipality_logotypes/Aneby Kommun/anebyvapen.png';
import arboga_emblem from '../images/municipality_logotypes/Arboga Kommun/arbogavapen.png';
import arjeplog_emblem from '../images/municipality_logotypes/Arjeplog Kommun/arjeplogvapen.png';
import arvidsjaur_emblem from '../images/municipality_logotypes/Arvidsjaur Kommun/arvidsjaurvapen.png';
import arvika_emblem from '../images/municipality_logotypes/Arvika Kommun/arvikavapen.png';
import askersund_emblem from '../images/municipality_logotypes/Askersund Kommun/askersundvapen.png';
import avesta_emblem from '../images/municipality_logotypes/Avesta Kommun/avestavapen.png';

import bengtsfors_emblem from '../images/municipality_logotypes/Bengtsfors Kommun/bengtsforsvapen.png';
import berg_emblem from '../images/municipality_logotypes/Bergs Kommun/bergsvapen.png';
import bjurholm_emblem from '../images/municipality_logotypes/Bjurholms Kommun/Bjurholmsvapen.png';
import bjuv_emblem from '../images/municipality_logotypes/Bjuv Kommun/bjuvvapen.png';
import boden_emblem from '../images/municipality_logotypes/Boden Kommun/bodenvapen.png';
import bollebygd_emblem from '../images/municipality_logotypes/Bollebygd Kommun/bollebygdvapen.png';
import bollnas_emblem from '../images/municipality_logotypes/Bollnäs Kommun/bollnasvapen.png';
import borgholm_emblem from '../images/municipality_logotypes/Borgholm Kommun/Borgholmvapen.png';
import borlange_emblem from '../images/municipality_logotypes/Borlänge Kommun/borlangevapen.png';
import boras_emblem from '../images/municipality_logotypes/Borås Stad/borasvapen.png';
import botkyrka_emblem from '../images/municipality_logotypes/Botkyrka Kommun/botkyrkavapen.png';
import boxholm_emblem from '../images/municipality_logotypes/Boxholm Kommun/boxholmvapen.png';
import bracke_emblem from '../images/municipality_logotypes/Bräcke Kommun/brackevapen.png';
import bromolla_emblem from '../images/municipality_logotypes/Bromölla Kommun/bromollavapen.png';
import burlov_emblem from '../images/municipality_logotypes/Burlövs Kommun/burlovvapen.png';
import bastad_emblem from '../images/municipality_logotypes/Båstads Kommun/bastadvapen.png';

import dals_ed_emblem from '../images/municipality_logotypes/Dals-Ed Kommun/dals-edvapen.png';
import danderyd_emblem from '../images/municipality_logotypes/Danderyds Kommun/danderydvapen.png';
import degerfors_emblem from '../images/municipality_logotypes/Degerfors Kommun/degerforsvapen.png';
import dorotea_emblem from '../images/municipality_logotypes/Dorotea Kommun/doroteavapen.png';

import eda_emblem from '../images/municipality_logotypes/Eda Kommun/edavapen.png';
import ekero_emblem from '../images/municipality_logotypes/Ekerö Kommun/ekerovapen.png';
import eksjo_emblem from '../images/municipality_logotypes/Eksjö Kommun/eksjovapen.png';
import emmaboda_emblem from '../images/municipality_logotypes/Emmaboda Kommun/emmabodavapen.png';
import enkoping_emblem from '../images/municipality_logotypes/Enköpings Kommun/enköpingvapen.png';
import eskilstuna_emblem from '../images/municipality_logotypes/Eskilstuna Kommun/eskilstunavapen.png';
import eslov_emblem from '../images/municipality_logotypes/Eslövs Kommun/eslovvapen.png';
import essunga_emblem from '../images/municipality_logotypes/Essunga Kommun/essungavapen.png';

import fagersta_emblem from '../images/municipality_logotypes/Fagersta Kommun/fagerstavapen.png';
import falkenberg_emblem from '../images/municipality_logotypes/Falkenbergs Kommun/falkenbergvapen.png';
import falkoping_emblem from '../images/municipality_logotypes/Falköpings Kommun/falkopingvapen.png';
import falu_emblem from '../images/municipality_logotypes/Falu Kommun/falunvapen.png';
import fargelanda_emblem from '../images/municipality_logotypes/Färgelanda Kommun/fargelandavapen.png';
import filipstad_emblem from '../images/municipality_logotypes/Filipstad Kommun/filipstadvapen.png';
import finspang_emblem from '../images/municipality_logotypes/Finspångs Kommun/finspangvapen.png';
import flen_emblem from '../images/municipality_logotypes/Flens Kommun/flenvapen.png';
import forshaga_emblem from '../images/municipality_logotypes/Forshaga Kommun/forshagavapen.png';

import gagnef_emblem from '../images/municipality_logotypes/Gagnefs Kommun/gagnefvapen.png';
import gallivare_emblem from '../images/municipality_logotypes/Gällivare Kommun/gallivarevapen.png';
import gavle_emblem from '../images/municipality_logotypes/Gävle Kommun/gavlevapen.png';
import gislaved_emblem from '../images/municipality_logotypes/Gislaveds Kommun/gislavedvapen.png';
import gnesta_emblem from '../images/municipality_logotypes/Gnesta Kommun/gnestavapen.png';
import gnosjo_emblem from '../images/municipality_logotypes/Gnosjö Kommun/gnosjovapen.png';
import goteborg_emblem from '../images/municipality_logotypes/Göteborgs Stad/goteborgvapen.png';
import gotene_emblem from '../images/municipality_logotypes/Götene Vapen/gotenevapen.png';
import gotland_emblem from '../images/municipality_logotypes/Gotland/gotlandvapen.png';
import grastorp_emblem from '../images/municipality_logotypes/Grästorps Kommun/grastorpvapen.png';
import grums_emblem from '../images/municipality_logotypes/Grums Kommun/grumsvapen.png';
import gullspang_emblem from '../images/municipality_logotypes/Gullspångs Kommun/gullspangvapen.png';

import habo_emblem from '../images/municipality_logotypes/Habo Kommun/habovapen.png';
import hagfors_emblem from '../images/municipality_logotypes/Hagfors Kommun/hagforsvapen.png';
import hallsberg_emblem from '../images/municipality_logotypes/Hallsbergs Kommun/hallsbergvapen.png';
import hallstahammar_emblem from '../images/municipality_logotypes/Hallstahammars Kommun/hallstahammarvapen.png';
import halmstad_emblem from '../images/municipality_logotypes/Halmstad Kommun/halmstadvapen.png';
import hammaro_emblem from '../images/municipality_logotypes/Hammarö Kommun/hammarovapen.png';
import haninge_emblem from '../images/municipality_logotypes/Haninge Kommun/haningevapen.png';

import landskrona_emblem from '../images/municipality_logotypes/Landskrona Stad/landskronavapen.png';
import lund_emblem from '../images/municipality_logotypes/Lunds Kommun/lundvapen.png';

import sjobo_emblem from '../images/municipality_logotypes/Sjöbo Kommun/sjobovapen.png';
import sodertalje_emblem from '../images/municipality_logotypes/Södertälje Kommun/sodertaljevapen.png';

import vellinge_emblem from '../images/municipality_logotypes/Vellinge Kommun/vellingevapen.png';

import ystad_emblem from '../images/municipality_logotypes/Ystad Kommun/ystadvapen.png';

export const workCities = [
  { id: '726', workCity: 'Ale', emblem: ale_emblem },
  { id: '728', workCity: 'Alingsås', emblem: alingsas_emblem },
  // TODO add emblem
  { id: '463', workCity: 'Älmhult', emblem: '' },
  // TODO add emblem
  { id: '351', workCity: 'Älvdalen', emblem: '' },
  { id: '450', workCity: 'Alvesta', emblem: alvesta_emblem },
  // TODO add emblem
  { id: '631', workCity: 'Älvkarleby', emblem: '' },
  // TODO add emblem
  { id: '489', workCity: 'Älvsbyn', emblem: '' },
  // TODO add emblem
  { id: '809', workCity: 'Åmål', emblem: '' },
  { id: '402', workCity: 'Aneby', emblem: aneby_emblem },
  // TODO add emblem
  { id: '702', workCity: 'Ånge', emblem: '' },
  // TODO add emblem
  { id: '549', workCity: 'Ängelholm', emblem: '' },
  { id: '706', workCity: 'Arboga', emblem: arboga_emblem },
  // TODO add emblem
  { id: '398', workCity: 'Åre', emblem: '' },
  // TODO add emblem
  { id: '664', workCity: 'Årjäng', emblem: '' },
  { id: '466', workCity: 'Arjeplog', emblem: arjeplog_emblem },
  { id: '468', workCity: 'Arvidsjaur', emblem: arvidsjaur_emblem },
  { id: '635', workCity: 'Arvika', emblem: arvika_emblem },
  // TODO add emblem
  { id: '692', workCity: 'Åsele', emblem: '' },
  { id: '813', workCity: 'Askersund', emblem: askersund_emblem },
  // TODO add emblem
  { id: '547', workCity: 'Åstorp', emblem: '' },
  // TODO add emblem
  { id: '854', workCity: 'Åtvidaberg', emblem: '' },
  { id: '288', workCity: 'Avesta', emblem: avesta_emblem },
  { id: '500', workCity: 'Båstad', emblem: bastad_emblem },
  { id: '730', workCity: 'Bengtsfors', emblem: bengtsfors_emblem },
  { id: '390', workCity: 'Berg', emblem: berg_emblem },
  { id: '666', workCity: 'Bjurholm', emblem: bjurholm_emblem },
  { id: '496', workCity: 'Bjuv', emblem: bjuv_emblem },
  { id: '470', workCity: 'Boden', emblem: boden_emblem },
  { id: '732', workCity: 'Bollebygd', emblem: bollebygd_emblem },
  { id: '355', workCity: 'Bollnäs', emblem: bollnas_emblem },
  { id: '734', workCity: 'Borås', emblem: boras_emblem },
  { id: '428', workCity: 'Borgholm', emblem: borgholm_emblem },
  { id: '326', workCity: 'Borlänge', emblem: borlange_emblem },
  { id: '554', workCity: 'Botkyrka', emblem: botkyrka_emblem },
  { id: '835', workCity: 'Boxholm', emblem: boxholm_emblem },
  { id: '391', workCity: 'Bräcke', emblem: bracke_emblem },
  { id: '498', workCity: 'Bromölla', emblem: bromolla_emblem },
  { id: '279', workCity: 'Burlöv', emblem: burlov_emblem },
  { id: '736', workCity: 'Dals-Ed', emblem: dals_ed_emblem },
  { id: '556', workCity: 'Danderyd', emblem: danderyd_emblem },
  { id: '815', workCity: 'Degerfors', emblem: degerfors_emblem },
  { id: '668', workCity: 'Dorotea', emblem: dorotea_emblem },
  { id: '637', workCity: 'Eda', emblem: eda_emblem },
  { id: '558', workCity: 'Ekerö', emblem: ekero_emblem },
  { id: '404', workCity: 'Eksjö', emblem: eksjo_emblem },
  { id: '430', workCity: 'Emmaboda', emblem: emmaboda_emblem },
  { id: '620', workCity: 'Enköping', emblem: enkoping_emblem },
  { id: '602', workCity: 'Eskilstuna', emblem: eskilstuna_emblem },
  { id: '502', workCity: 'Eslöv', emblem: eslov_emblem },
  { id: '737', workCity: 'Essunga', emblem: essunga_emblem },
  { id: '708', workCity: 'Fagersta', emblem: fagersta_emblem },
  { id: '377', workCity: 'Falkenberg', emblem: falkenberg_emblem },
  { id: '738', workCity: 'Falköping', emblem: falkoping_emblem },
  { id: '328', workCity: 'Falun', emblem: falu_emblem },
  { id: '740', workCity: 'Färgelanda', emblem: fargelanda_emblem },
  { id: '638', workCity: 'Filipstad', emblem: filipstad_emblem },
  { id: '837', workCity: 'Finspång', emblem: finspang_emblem },
  { id: '604', workCity: 'Flen', emblem: flen_emblem },
  { id: '640', workCity: 'Forshaga', emblem: forshaga_emblem },
  { id: '330', workCity: 'Gagnef', emblem: gagnef_emblem },
  { id: '472', workCity: 'Gällivare', emblem: gallivare_emblem },
  { id: '358', workCity: 'Gävle', emblem: gavle_emblem },
  { id: '406', workCity: 'Gislaved', emblem: gislaved_emblem },
  { id: '606', workCity: 'Gnesta', emblem: gnesta_emblem },
  { id: '408', workCity: 'Gnosjö', emblem: gnosjo_emblem },
  { id: '746', workCity: 'Göteborg', emblem: goteborg_emblem },
  { id: '748', workCity: 'Götene', emblem: gotene_emblem },
  { id: '353', workCity: 'Gotland', emblem: gotland_emblem },
  { id: '742', workCity: 'Grästorp', emblem: grastorp_emblem },
  { id: '642', workCity: 'Grums', emblem: grums_emblem },
  { id: '744', workCity: 'Gullspång', emblem: gullspang_emblem },
  { id: '410', workCity: 'Habo', emblem: habo_emblem },
  // TODO add emblem
  { id: '412', workCity: 'Håbo', emblem: '' },
  { id: '644', workCity: 'Hagfors', emblem: hagfors_emblem },
  // TODO add emblem
  { id: '819', workCity: 'Hällefors', emblem: '' },
  { id: '817', workCity: 'Hallsberg', emblem: hallsberg_emblem },
  { id: '710', workCity: 'Hallstahammar', emblem: hallstahammar_emblem },
  { id: '379', workCity: 'Halmstad', emblem: halmstad_emblem },
  { id: '646', workCity: 'Hammarö', emblem: hammaro_emblem },
  { id: '560', workCity: 'Haninge', emblem: haninge_emblem },
  // TODO add emblem
  { id: '474', workCity: 'Haparanda', emblem: '' },
  // TODO add emblem
  { id: '393', workCity: 'Härjedalen', emblem: '' },
  // TODO add emblem
  { id: '694', workCity: 'Härnösand', emblem: '' },
  // TODO add emblem
  { id: '754', workCity: 'Härryda', emblem: '' },
  // TODO add emblem
  { id: '506', workCity: 'Hässleholm', emblem: '' },
  // TODO add emblem
  { id: '622', workCity: 'Heby', emblem: '' },
  // TODO add emblem
  { id: '332', workCity: 'Hedemora', emblem: '' },
  // TODO add emblem
  { id: '504', workCity: 'Helsingborg', emblem: '' },
  // TODO add emblem
  { id: '750', workCity: 'Herrljunga', emblem: '' },
  // TODO add emblem
  { id: '752', workCity: 'Hjo', emblem: '' },
  // TODO add emblem
  { id: '364', workCity: 'Hofors', emblem: '' },
  // TODO add emblem
  { id: '508', workCity: 'Höganäs', emblem: '' },
  // TODO add emblem
  { id: '434', workCity: 'Högsby', emblem: '' },
  // TODO add emblem
  { id: '512', workCity: 'Höör', emblem: '' },
  // TODO add emblem
  { id: '510', workCity: 'Hörby', emblem: '' },
  // TODO add emblem
  { id: '562', workCity: 'Huddinge', emblem: '' },
  // TODO add emblem
  { id: '366', workCity: 'Hudiksvall', emblem: '' },
  // TODO add emblem
  { id: '432', workCity: 'Hultsfred', emblem: '' },
  // TODO add emblem
  { id: '381', workCity: 'Hylte', emblem: '' },
  // TODO add emblem
  { id: '564', workCity: 'Järfälla', emblem: '' },
  // TODO add emblem
  { id: '476', workCity: 'Jokkmokk', emblem: '' },
  // TODO add emblem
  { id: '284', workCity: 'Jönköping', emblem: '' },
  // TODO add emblem
  { id: '478', workCity: 'Kalix', emblem: '' },
  // TODO add emblem
  { id: '292', workCity: 'Kalmar', emblem: '' },
  // TODO add emblem
  { id: '755', workCity: 'Karlsborg', emblem: '' },
  // TODO add emblem
  { id: '316', workCity: 'Karlshamn', emblem: '' },
  // TODO add emblem
  { id: '821', workCity: 'Karlskoga', emblem: '' },
  // TODO add emblem
  { id: '318', workCity: 'Karlskrona', emblem: '' },
  // TODO add emblem
  { id: '648', workCity: 'Karlstad', emblem: '' },
  // TODO add emblem
  { id: '608', workCity: 'Katrineholm', emblem: '' },
  // TODO add emblem
  { id: '518', workCity: 'Kävlinge', emblem: '' },
  // TODO add emblem
  { id: '650', workCity: 'Kil', emblem: '' },
  // TODO add emblem
  { id: '839', workCity: 'Kinda', emblem: '' },
  // TODO add emblem
  { id: '480', workCity: 'Kiruna', emblem: '' },
  // TODO add emblem
  { id: '514', workCity: 'Klippan', emblem: '' },
  // TODO add emblem
  { id: '624', workCity: 'Knivsta', emblem: '' },
  // TODO add emblem
  { id: '714', workCity: 'Köping', emblem: '' },
  // TODO add emblem
  { id: '696', workCity: 'Kramfors', emblem: '' },
  // TODO add emblem
  { id: '516', workCity: 'Kristianstad', emblem: '' },
  // TODO add emblem
  { id: '652', workCity: 'Kristinehamn', emblem: '' },
  // TODO add emblem
  { id: '394', workCity: 'Krokom', emblem: '' },
  // TODO add emblem
  { id: '823', workCity: 'Kumla', emblem: '' },
  // TODO add emblem
  { id: '757', workCity: 'Kungälv', emblem: '' },
  // TODO add emblem
  { id: '382', workCity: 'Kungsbacka', emblem: '' },
  // TODO add emblem
  { id: '712', workCity: 'Kungsör', emblem: '' },
  // TODO add emblem
  { id: '384', workCity: 'Laholm', emblem: '' },
  { id: '520', workCity: 'Landskrona', emblem: landskrona_emblem },
  // TODO add emblem
  { id: '825', workCity: 'Laxå', emblem: '' },
  // TODO add emblem
  { id: '827', workCity: 'Lekeberg', emblem: '' },
  // TODO add emblem
  { id: '334', workCity: 'Leksand', emblem: '' },
  // TODO add emblem
  { id: '759', workCity: 'Lerum', emblem: '' },
  // TODO add emblem
  { id: '452', workCity: 'Lessebo', emblem: '' },
  // TODO add emblem
  { id: '567', workCity: 'Lidingö', emblem: '' },
  // TODO add emblem
  { id: '314', workCity: 'Lidköping', emblem: '' },
  // TODO add emblem
  { id: '761', workCity: 'Lilla Edet', emblem: '' },
  // TODO add emblem
  { id: '828', workCity: 'Lindesberg', emblem: '' },
  // TODO add emblem
  { id: '388', workCity: 'Linköping', emblem: '' },
  // TODO add emblem
  { id: '454', workCity: 'Ljungby', emblem: '' },
  // TODO add emblem
  { id: '368', workCity: 'Ljusdal', emblem: '' },
  // TODO add emblem
  { id: '830', workCity: 'Ljusnarsberg', emblem: '' },
  // TODO add emblem
  { id: '281', workCity: 'Lomma', emblem: '' },
  // TODO add emblem
  { id: '336', workCity: 'Ludvika', emblem: '' },
  // TODO add emblem
  { id: '483', workCity: 'Luleå', emblem: '' },
  { id: '275', workCity: 'Lund', emblem: lund_emblem },
  // TODO add emblem
  { id: '670', workCity: 'Lycksele', emblem: '' },
  // TODO add emblem
  { id: '763', workCity: 'Lysekil', emblem: '' },
  // TODO add emblem
  { id: '672', workCity: 'Malå', emblem: '' },
  // TODO add emblem
  { id: '301', workCity: 'Malmö', emblem: '' },
  // TODO add emblem
  { id: '338', workCity: 'Malung', emblem: '' },
  // TODO add emblem
  { id: '765', workCity: 'Mariestad', emblem: '' },
  // TODO add emblem
  { id: '767', workCity: 'Mark', emblem: '' },
  // TODO add emblem
  { id: '456', workCity: 'Markaryd', emblem: '' },
  // TODO add emblem
  { id: '768', workCity: 'Mellerud', emblem: '' },
  // TODO add emblem
  { id: '841', workCity: 'Mjölby', emblem: '' },
  // TODO add emblem
  { id: '772', workCity: 'Mölndal', emblem: '' },
  // TODO add emblem
  { id: '437', workCity: 'Mönsterås', emblem: '' },
  // TODO add emblem
  { id: '286', workCity: 'Mora', emblem: '' },
  // TODO add emblem
  { id: '439', workCity: 'Mörbylånga', emblem: '' },
  // TODO add emblem
  { id: '843', workCity: 'Motala', emblem: '' },
  // TODO add emblem
  { id: '413', workCity: 'Mullsjö', emblem: '' },
  // TODO add emblem
  { id: '770', workCity: 'Munkedal', emblem: '' },
  // TODO add emblem
  { id: '654', workCity: 'Munkfors', emblem: '' },
  // TODO add emblem
  { id: '569', workCity: 'Nacka', emblem: '' },
  // TODO add emblem
  { id: '415', workCity: 'Nässjö', emblem: '' },
  // TODO add emblem
  { id: '831', workCity: 'Nora', emblem: '' },
  // TODO add emblem
  { id: '716', workCity: 'Norberg', emblem: '' },
  // TODO add emblem
  { id: '370', workCity: 'Nordanstig', emblem: '' },
  // TODO add emblem
  { id: '674', workCity: 'Nordmaling', emblem: '' },
  // TODO add emblem
  { id: '845', workCity: 'Norrköping', emblem: '' },
  // TODO add emblem
  { id: '571', workCity: 'Norrtälje', emblem: '' },
  // TODO add emblem
  { id: '676', workCity: 'Norsjö', emblem: '' },
  // TODO add emblem
  { id: '298', workCity: 'Nybro', emblem: '' },
  // TODO add emblem
  { id: '610', workCity: 'Nyköping', emblem: '' },
  // TODO add emblem
  { id: '573', workCity: 'Nykvarn', emblem: '' },
  // TODO add emblem
  { id: '575', workCity: 'Nynäshamn', emblem: '' },
  // TODO add emblem
  { id: '371', workCity: 'Ockelbo', emblem: '' },
  // TODO add emblem
  { id: '811', workCity: 'Öckerö', emblem: '' },
  // TODO add emblem
  { id: '856', workCity: 'Ödeshög', emblem: '' },
  // TODO add emblem
  { id: '320', workCity: 'Olofström', emblem: '' },
  // TODO add emblem
  { id: '833', workCity: 'Örebro', emblem: '' },
  // TODO add emblem
  { id: '551', workCity: 'Örkelljunga', emblem: '' },
  // TODO add emblem
  { id: '704', workCity: 'Örnsköldsvik', emblem: '' },
  // TODO add emblem
  { id: '340', workCity: 'Orsa', emblem: '' },
  // TODO add emblem
  { id: '774', workCity: 'Orust', emblem: '' },
  // TODO add emblem
  { id: '522', workCity: 'Osby', emblem: '' },
  // TODO add emblem
  { id: '443', workCity: 'Oskarshamn', emblem: '' },
  // TODO add emblem
  { id: '601', workCity: 'Österåker', emblem: '' },
  // TODO add emblem
  { id: '400', workCity: 'Östersund', emblem: '' },
  // TODO add emblem
  { id: '633', workCity: 'Östhammar', emblem: '' },
  // TODO add emblem
  { id: '553', workCity: 'Östra Göinge', emblem: '' },
  // TODO add emblem
  { id: '373', workCity: 'Ovanåker', emblem: '' },
  // TODO add emblem
  { id: '491', workCity: 'Överkalix', emblem: '' },
  // TODO add emblem
  { id: '493', workCity: 'Övertorneå', emblem: '' },
  // TODO add emblem
  { id: '612', workCity: 'Oxelösund', emblem: '' },
  // TODO add emblem
  { id: '485', workCity: 'Pajala', emblem: '' },
  // TODO add emblem
  { id: '775', workCity: 'Partille', emblem: '' },
  // TODO add emblem
  { id: '525', workCity: 'Perstorp', emblem: '' },
  // TODO add emblem
  { id: '487', workCity: 'Piteå', emblem: '' },
  // TODO add emblem
  { id: '395', workCity: 'Ragunda', emblem: '' },
  // TODO add emblem
  { id: '342', workCity: 'Rättvik', emblem: '' },
  // TODO add emblem
  { id: '678', workCity: 'Robertsfors', emblem: '' },
  // TODO add emblem
  { id: '322', workCity: 'Ronneby', emblem: '' },
  // TODO add emblem
  { id: '660', workCity: 'Säffle', emblem: '' },
  // TODO add emblem
  { id: '718', workCity: 'Sala', emblem: '' },
  // TODO add emblem
  { id: '577', workCity: 'Salem', emblem: '' },
  // TODO add emblem
  { id: '277', workCity: 'Sandviken', emblem: '' },
  // TODO add emblem
  { id: '346', workCity: 'Säter', emblem: '' },
  // TODO add emblem
  { id: '417', workCity: 'Sävsjö', emblem: '' },
  // TODO add emblem
  { id: '579', workCity: 'Sigtuna', emblem: '' },
  // TODO add emblem
  { id: '527', workCity: 'Simrishamn', emblem: '' },
  { id: '529', workCity: 'Sjöbo', emblem: sjobo_emblem },
  // TODO add emblem
  { id: '777', workCity: 'Skara', emblem: '' },
  // TODO add emblem
  { id: '679', workCity: 'Skellefteå', emblem: '' },
  // TODO add emblem
  { id: '720', workCity: 'Skinnskatteberg', emblem: '' },
  // TODO add emblem
  { id: '779', workCity: 'Skövde', emblem: '' },
  // TODO add emblem
  { id: '531', workCity: 'Skurup', emblem: '' },
  // TODO add emblem
  { id: '344', workCity: 'Smedjebacken', emblem: '' },
  // TODO add emblem
  { id: '375', workCity: 'Söderhamn', emblem: '' },
  // TODO add emblem
  { id: '847', workCity: 'Söderköping', emblem: '' },
  { id: '290', workCity: 'Södertälje', emblem: sodertalje_emblem },
  // TODO add emblem
  { id: '698', workCity: 'Sollefteå', emblem: '' },
  // TODO add emblem
  { id: '581', workCity: 'Sollentuna', emblem: '' },
  // TODO add emblem
  { id: '583', workCity: 'Solna', emblem: '' },
  // TODO add emblem
  { id: '324', workCity: 'Sölvesborg', emblem: '' },
  // TODO add emblem
  { id: '681', workCity: 'Sorsele', emblem: '' },
  // TODO add emblem
  { id: '781', workCity: 'Sotenäs', emblem: '' },
  // TODO add emblem
  { id: '533', workCity: 'Staffanstorp', emblem: '' },
  // TODO add emblem
  { id: '782', workCity: 'Stenungsund', emblem: '' },
  // TODO add emblem
  { id: '294', workCity: 'Stockholm', emblem: '' },
  // TODO add emblem
  { id: '656', workCity: 'Storfors', emblem: '' },
  // TODO add emblem
  { id: '682', workCity: 'Storuman', emblem: '' },
  // TODO add emblem
  { id: '614', workCity: 'Strängnäs', emblem: '' },
  // TODO add emblem
  { id: '784', workCity: 'Strömstad', emblem: '' },
  // TODO add emblem
  { id: '396', workCity: 'Strömsund', emblem: '' },
  // TODO add emblem
  { id: '585', workCity: 'Sundbyberg', emblem: '' },
  // TODO add emblem
  { id: '312', workCity: 'Sundsvall', emblem: '' },
  // TODO add emblem
  { id: '658', workCity: 'Sunne', emblem: '' },
  // TODO add emblem
  { id: '722', workCity: 'Surahammar', emblem: '' },
  // TODO add emblem
  { id: '535', workCity: 'Svalöv', emblem: '' },
  // TODO add emblem
  { id: '537', workCity: 'Svedala', emblem: '' },
  // TODO add emblem
  { id: '786', workCity: 'Svenljunga', emblem: '' },
  // TODO add emblem
  { id: '589', workCity: 'Täby', emblem: '' },
  // TODO add emblem
  { id: '788', workCity: 'Tanum', emblem: '' },
  // TODO add emblem
  { id: '789', workCity: 'Tibro', emblem: '' },
  // TODO add emblem
  { id: '791', workCity: 'Tidaholm', emblem: '' },
  // TODO add emblem
  { id: '627', workCity: 'Tierp', emblem: '' },
  // TODO add emblem
  { id: '700', workCity: 'Timrå', emblem: '' },
  // TODO add emblem
  { id: '458', workCity: 'Tingsryd', emblem: '' },
  // TODO add emblem
  { id: '793', workCity: 'Tjörn', emblem: '' },
  // TODO add emblem
  { id: '539', workCity: 'Tomelilla', emblem: '' },
  // TODO add emblem
  { id: '797', workCity: 'Töreboda', emblem: '' },
  // TODO add emblem
  { id: '445', workCity: 'Torsås', emblem: '' },
  // TODO add emblem
  { id: '662', workCity: 'Torsby', emblem: '' },
  // TODO add emblem
  { id: '419', workCity: 'Tranås', emblem: '' },
  // TODO add emblem
  { id: '794', workCity: 'Tranemo', emblem: '' },
  // TODO add emblem
  { id: '541', workCity: 'Trelleborg', emblem: '' },
  // TODO add emblem
  { id: '795', workCity: 'Trollhättan', emblem: '' },
  // TODO add emblem
  { id: '616', workCity: 'Trosa', emblem: '' },
  // TODO add emblem
  { id: '587', workCity: 'Tyresö', emblem: '' },
  // TODO add emblem
  { id: '799', workCity: 'Uddevalla', emblem: '' },
  // TODO add emblem
  { id: '801', workCity: 'Ulricehamn', emblem: '' },
  // TODO add emblem
  { id: '684', workCity: 'Umeå', emblem: '' },
  // TODO add emblem
  { id: '591', workCity: 'Upplands Väsby', emblem: '' },
  // TODO add emblem
  { id: '593', workCity: 'Upplands-Bro', emblem: '' },
  // TODO add emblem
  { id: '629', workCity: 'Uppsala', emblem: '' },
  // TODO add emblem
  { id: '460', workCity: 'Uppvidinge', emblem: '' },
  // TODO add emblem
  { id: '849', workCity: 'Vadstena', emblem: '' },
  // TODO add emblem
  { id: '421', workCity: 'Vaggeryd', emblem: '' },
  // TODO add emblem
  { id: '851', workCity: 'Valdemarsvik', emblem: '' },
  // TODO add emblem
  { id: '595', workCity: 'Vallentuna', emblem: '' },
  // TODO add emblem
  { id: '807', workCity: 'Vänersborg', emblem: '' },
  // TODO add emblem
  { id: '690', workCity: 'Vännäs', emblem: '' },
  // TODO add emblem
  { id: '348', workCity: 'Vansbro', emblem: '' },
  // TODO add emblem
  { id: '803', workCity: 'Vara', emblem: '' },
  // TODO add emblem
  { id: '386', workCity: 'Varberg', emblem: '' },
  // TODO add emblem
  { id: '805', workCity: 'Vårgårda', emblem: '' },
  // TODO add emblem
  { id: '599', workCity: 'Värmdö', emblem: '' },
  // TODO add emblem
  { id: '426', workCity: 'Värnamo', emblem: '' },
  // TODO add emblem
  { id: '724', workCity: 'Västerås', emblem: '' },
  // TODO add emblem
  { id: '448', workCity: 'Västervik', emblem: '' },
  // TODO add emblem
  { id: '597', workCity: 'Vaxholm', emblem: '' },
  // TODO add emblem
  { id: '461', workCity: 'Växjö', emblem: '' },
  { id: '543', workCity: 'Vellinge', emblem: vellinge_emblem },
  // TODO add emblem
  { id: '424', workCity: 'Vetlanda', emblem: '' },
  // TODO add emblem
  { id: '686', workCity: 'Vilhelmina', emblem: '' },
  // TODO add emblem
  { id: '446', workCity: 'Vimmerby', emblem: '' },
  // TODO add emblem
  { id: '688', workCity: 'Vindeln', emblem: '' },
  // TODO add emblem
  { id: '618', workCity: 'Vingåker', emblem: '' },
  // TODO add emblem
  { id: '853', workCity: 'Ydre', emblem: '' },
  { id: '545', workCity: 'Ystad', emblem: ystad_emblem },
];
