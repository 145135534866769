import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import queryString from 'query-string';
import merge from 'lodash.merge';
import { withResizeDetector } from 'react-resize-detector';
import { ThemeProvider } from 'styled-components';
import { SearchWidget } from './components/SearchWidget';
import { Editor } from './components/Editor';
import { defaultTheme } from './styles/defaultTheme';
import { DemoContainer } from './styles/components';
import { workCities } from './data/workCities';
import { IApp, IWidgetSettings } from './interfaces';

const App: React.FC<IApp> = ({ width, height }) => {
  const [widgetSettings, setWidgetSettings] = useState<IWidgetSettings>({});
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    window.addEventListener('load', function () {
      window.parent.postMessage(
        {
          event: 'load',
          height: document.body.scrollHeight,
        },
        '*'
      );
    });
  }, []);

  useEffect(() => {
    // Everytime height is changed send height to parent (client's site)
    window.parent.postMessage(
      {
        event: 'resize',
        height: height,
      },
      '*'
    );
  }, [height]);

  useEffect(() => {
    // Parse query params
    const parsed = queryString.parse(window.location.search);
    const workCityId = parsed.workCityId || '275';
    const radius = parsed.radius || '20';
    const query = parsed.query || '';
    const queries = (parsed.queries as string)?.split(',') || '';

    let header;

    if (parsed.header) {
      header = parsed.header;
    } else {
      const municipalityName = workCities.filter(
        (city) => city.id === workCityId
      )?.[0]?.workCity;

      if (municipalityName) {
        header = `Lediga jobb i ${municipalityName} kommun och med omnejd`;
      } else {
        header = 'Lediga jobb i din kommun';
      }
    }

    const bgColor = parsed.bgColor || '#6783ff';

    setWidgetSettings({
      workCityId,
      radius,
      query,
      queries,
      header,
      bgColor,
    });
    setQuery(query as string);
  }, []);

  return (
    <Router>
      <ThemeProvider
        theme={merge({}, defaultTheme, { bgColor: widgetSettings?.bgColor })}
      >
        <Switch>
          <Route exact path="/editor">
            <DemoContainer>
              <section className="widget-container">
                <SearchWidget
                  widgetSettings={widgetSettings}
                  initialQuery={query}
                  editorMode={true}
                />
              </section>
              <Editor
                widgetSettings={widgetSettings}
                updateWidgetSettings={(data: any) => setWidgetSettings(data)}
              />
            </DemoContainer>
          </Route>

          <Route path="/">
            <SearchWidget
              widgetSettings={widgetSettings}
              initialQuery={query}
            />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );
};

export default withResizeDetector(App);
