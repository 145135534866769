import styled, { css } from 'styled-components/macro';
import searchIcon from '../images/search-icon.svg';
import { bounce } from './animations';
import { lighten, darken, getLuminance } from 'polished';

export const DemoContainer = styled.main`
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  margin: 0 auto;
  background: #fafafa;

  & > .widget-container {
    max-width: 400px;
    margin: 40px auto 0;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  background: ${({ theme }) => theme.bgColor};
  color: ${({ theme }) => theme.color};
  font-size: 12px;
  position: relative;

  & > .municipality-emblem {
    max-height: 50px;
    width: auto;
    margin-bottom: 10px;

    @media only screen and (min-width: 350px) {
      position: absolute;
      left: 30px;
      margin-bottom: 0;
    }
  }

  & > a.powered-by {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;

    @media only screen and (min-width: 350px) {
      flex-direction: row;
    }

    & > img {
      min-width: 120px;
      max-width: 120px;

      @media only screen and (min-width: 350px) {
        margin-left: 5px;
      }
    }
  }
`;

interface IProps {
  scrolled?: boolean;
}

export const SearchWidgetContainer = styled.div<IProps>`
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0 20px;
    background: ${({ theme }) => theme.bgColor};
    color: ${({ theme }) => theme.color};

    & > h1 {
      font-size: 20px;
      font-weight: 600;
    }

    & > img {
      max-width: 100px;
      margin-bottom: 5px;
    }
  }

  span.arrow {
    border: ${({ theme }) => `solid ${theme.bgColor}`};
    border-width: 0 2.5px 2.5px 0;
    display: inline-block;
    padding: 3.5px;

    &.left {
      transform: rotate(135deg);
    }

    &.right {
      transform: rotate(-45deg);
    }

    &.bottom {
      border: solid #000;
      border-width: 0 2.5px 2.5px 0;
      transform: rotate(45deg);
      animation: ${({ scrolled }) =>
        !scrolled
          ? css`
              ${bounce} 3s linear infinite
            `
          : 'none'};
    }
  }

  .scroll-indicator {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: #fff;
  }
`;

export const Search = styled.section``;

export const SearchResult = styled.section`
  background: #fff;
  border-left: ${({ theme }) => `10px solid ${theme.bgColor}`};
  border-right: ${({ theme }) => `10px solid ${theme.bgColor}`};
  padding-right: 5px;
  overflow: hidden;
`;

export const Result = styled.div`
  padding: 12px;
`;

export const AdsList = styled.ul`
  max-height: 400px;
  list-style: none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.bgColor};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  & > li:first-child {
    margin-top: 10px;
  }
`;

export const Ad = styled.li`
  display: flex;
  margin-bottom: 20px;
  padding: 5px 0;

  &:hover {
    background: #e6e6e6;
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    max-width: 280px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .employer-name {
    font-size: 12px;
    font-weight: bold;
    color: #727272;
  }

  .work-city,
  .published-at {
    font-size: 11px;
  }
`;

export const EmployerLogoContainer = styled.div`
  flex: 0 1 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmployerLogo = styled.img`
  display: block;
  max-width: 50px;
  max-height: 50px;
`;

export const EmployerLogoPlaceholder = styled.div`
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.bgColor};
  color: ${({ theme }) => theme.color};
  border-radius: 50%;
  font-size: 16px;
  font-weight: 700;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;

  .react-paginate {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px 10px;

    li {
      display: inline-block;
      color: #6c7c91;
      position: relative;

      &:after {
        content: '';
        display: none;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 25px;
        background: ${({ theme }) => theme.bgColor};
      }

      &.active {
        color: ${({ theme }) => theme.bgColor};
        font-weight: bold;

        &:after {
          display: inline-block;
        }
      }

      &:hover {
        color: ${({ theme }) => theme.bgColor};

        &:after {
          display: inline-block;
        }
      }

      a {
        padding: 5px 10px;
      }
    }
  }

  button {
    background: none;
    border: none;
    padding: 10px;
  }
`;

export const Form = styled.form`
  display: flex;
  padding: 20px;
  background: ${({ theme }) => theme.bgColor};
`;

export const Input = styled.input`
  height: 40px;
  width: 100%;
  border: 0;
  background-image: url(${searchIcon});
  background-position: 12px center;
  background-size: 16px;
  background-repeat: no-repeat;
  padding-left: 36px;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  font-size: 16px;
`;

export const Button = styled.button`
  // CSS reset
  margin: 0;
  padding: 9px 15px;
  width: auto;
  overflow: visible;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  border: 0;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  // Styles
  display: flex;
  min-height: 40px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;

  color: ${({ theme }) =>
    getLuminance(lighten(0.2, theme.bgColor)) <= 0.5 ? '#fff' : '#000'};
  background: ${({ theme }) =>
    getLuminance(theme.bgColor) <= 0.5
      ? lighten(0.2, theme.bgColor)
      : darken(0.2, theme.bgColor)};
  border-radius: 2px;

  &:hover {
    background: ${({ theme }) =>
      getLuminance(theme.bgColor) <= 0.5
        ? lighten(0.3, theme.bgColor)
        : darken(0.3, theme.bgColor)};
  }
`;
