import React, { useEffect, useRef, useState } from 'react';
import {
  Ad,
  AdsList,
  Button,
  EmployerLogo,
  EmployerLogoContainer,
  EmployerLogoPlaceholder,
  Footer,
  Form,
  Input,
  PaginationContainer,
  Result,
  Search,
  SearchResult,
  SearchWidgetContainer,
} from '../../styles/components';
import { Suggestions } from '../Suggestions';
import { getAds } from '../../api/getAds';
import { workCities } from '../../data/workCities';
import { formatDistanceToNow } from 'date-fns';
import { sv } from 'date-fns/locale';
import ReactPaginate from 'react-paginate';
import { SelectedAd } from '../SelectedAd';
import { IAd, IWidgetSettings } from '../../interfaces';
import logo from '../../images/auranest-logo.svg';

interface ISearchWidget {
  widgetSettings: IWidgetSettings;
  initialQuery?: string;
  editorMode?: boolean;
}

export const SearchWidget: React.FC<ISearchWidget> = ({
  widgetSettings,
  initialQuery,
  editorMode,
}) => {
  const [query, setQuery] = useState(initialQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [selectedAd, setSelectedAd] = useState<IAd | null>(null);
  const adsList = useRef<any>(null);
  const [showArrow, setShowArrow] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [error, setError] = useState(false);
  const didMountRef = useRef(false);
  const [lastSearch, setLastSearch] = useState('');

  useEffect(() => {
    // Hack
    if (editorMode) {
      setQuery(widgetSettings.query);
    }
  }, [widgetSettings]);

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);

  useEffect(() => {
    if (widgetSettings.workCityId && !editorMode) {
      getAds({
        workCityId: widgetSettings.workCityId,
        radius: widgetSettings.radius,
        query: initialQuery,
        pageNumber: 1,
      }).then((ads) => {
        if (ads) {
          setSearchResult(ads?.data);
          setLastPage(Math.ceil(ads?.data.hitCount / 20));
          setLastSearch(
            `workCityId=${widgetSettings.workCityId}&radius=${
              widgetSettings.radius
            }&query=${query}&pageNumber=${1}`
          );
        } else {
          setError(true);
        }
      });
    }
  }, [widgetSettings]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      lastSearch !==
      `workCityId=${widgetSettings.workCityId}&radius=${
        widgetSettings.radius
      }&query=${query}&pageNumber=${1}`
    ) {
      getAds({
        workCityId: widgetSettings.workCityId,
        radius: widgetSettings.radius,
        query: query,
        pageNumber: 1,
      }).then((ads) => {
        if (ads) {
          setError(false);
          setCurrentPage(1);
          setSearchResult(ads?.data);
          setLastPage(Math.ceil(ads?.data.hitCount / 20));
          setLastSearch(
            `workCityId=${widgetSettings.workCityId}&radius=${
              widgetSettings.radius
            }&query=${query}&pageNumber=${1}`
          );
        } else {
          setError(true);
        }
      });
    }
  };

  const paginate = (pageNumber: number) => {
    if (currentPage + pageNumber < 1 || currentPage + pageNumber > lastPage) {
      return;
    }

    setCurrentPage(currentPage + pageNumber);
  };

  useEffect(() => {
    // This will run everytime currentPage is updated
    if (didMountRef.current) {
      getAds({
        workCityId: widgetSettings.workCityId,
        radius: widgetSettings.radius,
        query: query,
        pageNumber: currentPage,
      }).then((ads) => {
        setSearchResult(ads?.data);
        setLastPage(Math.ceil(ads?.data.hitCount / 20));
        const adsList = window.document.querySelector('#ads-list');
        adsList?.scrollTo(0, 0);
      });
    } else {
      didMountRef.current = true;
    }
  }, [currentPage]);

  useEffect(() => {
    // If query is erased, clear ads and pagination from state
    if (!query) {
      setSearchResult(null);
    }
  }, [query]);

  const handleScroll = () => {
    setScrolled(true);
    const bottom =
      adsList.current.scrollHeight - adsList.current.scrollTop ===
      adsList.current.clientHeight;

    if (bottom) {
      setShowArrow(false);
    } else {
      if (!showArrow) {
        setShowArrow(true);
      }
    }
  };

  return (
    <SearchWidgetContainer scrolled={scrolled}>
      <header className="header">
        <h1>{widgetSettings?.header}</h1>
      </header>

      <Search>
        <Form onSubmit={handleSubmit}>
          <Input
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            value={query}
            placeholder="Ange sökord (t.ex. ekonomi)"
          />
          <Button>Sök</Button>
        </Form>

        {widgetSettings.queries && (
          <Suggestions
            queries={widgetSettings.queries}
            onClick={(query) => {
              setQuery(query);
              getAds({
                workCityId: widgetSettings.workCityId,
                radius: widgetSettings.radius,
                query: query,
                pageNumber: 1,
              }).then((ads) => {
                setCurrentPage(1);
                setSearchResult(ads?.data);
                setLastPage(Math.ceil(ads?.data.hitCount / 20));
              });
            }}
          />
        )}
      </Search>

      <SearchResult>
        <div style={{ display: selectedAd ? 'none' : 'block' }}>
          {searchResult?.hits.length > 0 && (
            <Result>
              Just nu finns det{' '}
              <span style={{ fontWeight: 600 }}>{searchResult?.hitCount}</span>{' '}
              lediga jobb i{' '}
              {
                workCities.filter(
                  (city) => city.id === widgetSettings.workCityId
                )?.[0]?.workCity
              }{' '}
              och med omnejd ({widgetSettings.radius}km)
            </Result>
          )}

          {searchResult?.hits.length === 0 && (
            <Result>Din sökning gav tyvärr inga resultat</Result>
          )}

          {error && (
            <Result>Någonting gick tyvärr fel, försök igen lite senare</Result>
          )}

          <AdsList id="ads-list" ref={adsList} onScroll={handleScroll}>
            {searchResult?.hits.map((ad: any, i: number) => {
              return (
                <Ad key={i} onClick={() => setSelectedAd(ad)}>
                  {ad.employerLogo ? (
                    <EmployerLogoContainer>
                      <EmployerLogo src={ad.employerLogo} />
                    </EmployerLogoContainer>
                  ) : (
                    <EmployerLogoContainer>
                      <EmployerLogoPlaceholder>
                        {ad.employerName ? ad.employerName.charAt(0).toUpperCase() : ad.header.charAt(0).toUpperCase()}
                      </EmployerLogoPlaceholder>
                    </EmployerLogoContainer>
                  )}
                  <div>
                    <header className="title">{ad.header}</header>
                    <div className="employer-name">{ad.employerName}</div>
                    <div className="work-city">{ad.workCity}</div>
                    {ad.publishDate && (
                      <div className="published-at">
                        Publicerad för{' '}
                        {formatDistanceToNow(Date.parse(ad.publishDate), {
                          locale: sv,
                          addSuffix: true,
                        })}
                      </div>
                    )}
                  </div>
                </Ad>
              );
            })}
            {searchResult?.hits.length > 0 && (
              <li className="scroll-indicator">
                <span
                  className="arrow bottom"
                  style={{ borderColor: showArrow ? '#000' : '#ddd' }}
                />
              </li>
            )}
          </AdsList>

          {searchResult?.hits.length > 0 && (
            <PaginationContainer>
              <ReactPaginate
                pageCount={lastPage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                previousLabel={
                  lastPage > 1 && (
                    <button onClick={() => paginate(-1)}>
                      <span className="arrow left" />
                    </button>
                  )
                }
                nextLabel={
                  lastPage > 1 && (
                    <button onClick={() => paginate(1)}>
                      <span className="arrow right" />
                    </button>
                  )
                }
                onPageChange={(pageNumber) =>
                  setCurrentPage(pageNumber.selected + 1)
                }
                containerClassName={'react-paginate'}
                activeClassName={'active'}
                forcePage={currentPage - 1}
              />
            </PaginationContainer>
          )}
        </div>

        {selectedAd && (
          <SelectedAd
            onClick={() => setSelectedAd(null)}
            deadline={selectedAd.deadline}
            descriptionText={selectedAd.descriptionText}
            employerName={selectedAd.employerName}
            employerLogo={selectedAd.employerLogo}
            header={selectedAd.header}
            url={selectedAd.url}
            workCity={selectedAd.workCity}
            workTitle={selectedAd.workTitle}
          />
        )}
      </SearchResult>

      <Footer>
        {/* If we have municipality emlbem, render img */}
        {workCities.filter((city) => city.id === widgetSettings.workCityId)?.[0]
          ?.emblem && (
          <img
            alt={
              workCities.filter(
                (city) => city.id === widgetSettings.workCityId
              )?.[0]?.workCity
            }
            src={
              workCities.filter(
                (city) => city.id === widgetSettings.workCityId
              )?.[0]?.emblem
            }
            className="municipality-emblem"
          />
        )}

        <a
          href="https://auranest.com/sv/"
          target="_blank"
          rel="noopener noreferrer"
          className="powered-by"
        >
          Powered by
          <img alt="Auranest" src={logo} />
        </a>
      </Footer>
    </SearchWidgetContainer>
  );
};
