import React from 'react';
import styled from 'styled-components/macro';
import { formatDistanceToNow } from 'date-fns';
import { sv } from 'date-fns/locale';
import { Button } from '../../styles/components';
import { IAd } from '../../interfaces';

interface ISelectedAd extends IAd {
  onClick?: () => void;
}

export const SelectedAd: React.FC<ISelectedAd> = ({
  onClick,
  deadline,
  descriptionText,
  employerName,
  employerLogo,
  header,
  url,
  workCity,
  workTitle,
}) => (
  <SelectedAdContainer>
    <Button onClick={onClick}>Gå tillbaka</Button>

    <header className="title">{header}</header>

    <div className="metadata">
      {employerLogo ? (
        <>
          <div>
            {workCity && (
              <div>
                <p className="key">Plats</p>
                <p>{workCity}</p>
              </div>
            )}

            {employerLogo && (
              <div className="logo-container">
                <img
                  alt={employerName ?? 'Företagslogotyp'}
                  src={employerLogo}
                />
              </div>
            )}
          </div>

          <div>
            {employerName && (
              <div>
                <p className="key">Arbetsgivare</p>
                <p>{employerName}</p>
              </div>
            )}

            {workTitle && (
              <div>
                <p className="key">Position</p>
                <p>{workTitle}</p>
              </div>
            )}

            <div>
              <p className="key">Sista ansökningsdag</p>
              <p>
                {deadline
                  ? formatDistanceToNow(Date.parse(deadline), {
                      locale: sv,
                      addSuffix: true,
                    })
                  : 'Annonsen är fortfarande aktiv.'}
              </p>
            </div>

            <div>
              <p className="key">Länk till annons</p>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {workCity && (
              <div>
                <p className="key">Plats</p>
                <p>{workCity}</p>
              </div>
            )}

            {workTitle && (
              <div>
                <p className="key">Position</p>
                <p>{workTitle}</p>
              </div>
            )}
          </div>

          <div>
            {employerName && (
              <div>
                <p className="key">Arbetsgivare</p>
                <p>{employerName}</p>
              </div>
            )}

            <div>
              <p className="key">Sista ansökningsdag</p>
              <p>
                {deadline
                  ? formatDistanceToNow(Date.parse(deadline), {
                      locale: sv,
                      addSuffix: true,
                    })
                  : 'Annonsen är fortfarande aktiv.'}
              </p>
            </div>

            <div>
              <p className="key">Länk till annons</p>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </div>
          </div>
        </>
      )}
    </div>

    <p className="description-text">{descriptionText}</p>

    <Button onClick={onClick}>Gå tillbaka</Button>
  </SelectedAdContainer>
);

const SelectedAdContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: #fff;
  overflow: scroll;

  max-height: 511px;
  overflow-x: hidden;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.bgColor};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .metadata {
    margin-bottom: 20px;
    font-size: 12px;
    word-break: break-word;

    & > div {
      display: inline-block;
      width: 50%;
      vertical-align: top;

      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }

      & > div {
        margin-bottom: 15px;
      }
    }

    .logo-container {
      flex: 0 1 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      & > img {
        display: block;
        max-width: 300px;
        max-height: 100px;
      }
    }
  }

  .description-text {
    width: 100%;
    font-size: 14px;
    white-space: pre-line;
    margin-bottom: 20px;
  }

  .key {
    font-weight: 600;
  }
`;
