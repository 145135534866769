import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { SketchPicker } from 'react-color';
import Select from 'react-select';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { IWidgetSettings } from '../../interfaces';
import { workCities } from '../../data/workCities';
import { generateSnippet } from '../../utils/generateSnippet';

interface IEditor {
  widgetSettings?: IWidgetSettings;
  updateWidgetSettings?: any;
}

export const Editor: React.FC<IEditor> = ({
  widgetSettings,
  updateWidgetSettings,
}) => {
  const { register, handleSubmit } = useForm();
  const [color, setColor] = useState('#4d6dff');
  const [url, setUrl] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  useEffect(() => {
    setColor(widgetSettings?.bgColor);
  }, [widgetSettings]);

  const onSubmit = (data: any) => {
    const baseUrl = 'https://search-widget.auradevelop.com/';

    let url = '?';

    if (widgetSettings?.workCityId) {
      url += `workCityId=${encodeURIComponent(widgetSettings.workCityId)}`;
    }

    if (data.radius) {
      url += `&radius=${encodeURIComponent(data.radius)}`;
    }

    if (data.query) {
      url += `&query=${encodeURIComponent(data.query)}`;
    }

    if (data.queries) {
      url += `&queries=${encodeURIComponent(data.queries)}`;
    }

    if (data.header) {
      url += `&header=${encodeURIComponent(data.header)}`;
    }

    if (color) {
      url += `&bgColor=${encodeURIComponent(color)}`;
    }

    setUrl(baseUrl + url);
  };


  return (
    <EditorContainer>
      <header className="editor-header">
        <h1>Konfigurera widget</h1>
      </header>

      <p className="editor-description">
        Här kan du konfigurera din widget. Fyll i önskade inställningar och
        tryck på "GENERERA WIDGET-KOD".
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputField className="workCityId">
          <label htmlFor="workCityId">Kommun</label>
          <Select
            getOptionLabel={(option) => option.workCity}
            getOptionValue={(option) => option.id}
            onChange={(option: any) => {
              if (option) {
                updateWidgetSettings({
                  ...widgetSettings,
                  header: `Lediga jobb i ${option.workCity} kommun och med omnejd`,
                  workCityId: option.id,
                });
              }
            }}
            options={workCities.sort(function (a, b) {
              return a.workCity.localeCompare(b.workCity);
            })}
            placeholder="Välj kommun..."
          />
        </InputField>

        <InputField className="radius">
          <label htmlFor="radius">Radius (km)</label>
          <Input
            id="radius"
            name="radius"
            ref={register}
            defaultValue={widgetSettings?.radius}
            onChange={(event) =>
              updateWidgetSettings({
                ...widgetSettings,
                radius: event.target.value,
              })
            }
          />
        </InputField>

        <InputField className="bgColor">
          <label
            htmlFor="bgColor"
            onClick={() =>
              setShowColorPicker((showColorPicker) => !showColorPicker)
            }
          >
            Färg (Tryck på rutan för att välja färg)
          </label>
          <ColorPreview
            color={color}
            onClick={() =>
              setShowColorPicker((showColorPicker) => !showColorPicker)
            }
          />
          <ColorContainer
            style={{ display: showColorPicker ? 'block' : 'none' }}
          >
            <SketchPicker
              color={widgetSettings?.bgColor}
              onChangeComplete={(color) => {
                setColor(color.hex);
                updateWidgetSettings({
                  ...widgetSettings,
                  bgColor: color.hex,
                });
              }}
            />
          </ColorContainer>
        </InputField>

        <InputField className="header">
          <label htmlFor="header">
            Rubrik på widget
            <p>Standardvärde: Lediga jobb i (vald kommun) och med omnejd</p>
          </label>
          <Input
            id="header"
            name="header"
            ref={register}
            defaultValue=""
            onChange={(event) =>
              updateWidgetSettings({
                ...widgetSettings,
                header: event.target.value,
              })
            }
          />
        </InputField>

        <InputField className="query">
          <label htmlFor="query">Färdigifylld sökterm</label>
          <Input
            id="query"
            name="query"
            ref={register}
            defaultValue={widgetSettings?.query}
            onChange={(event) =>
              updateWidgetSettings({
                ...widgetSettings,
                query: event.target.value,
              })
            }
          />
        </InputField>

        <InputField className="queries">
          <label htmlFor="queries">Sökförslag (separera med komma)</label>
          <Input
            id="queries"
            name="queries"
            ref={register}
            defaultValue={widgetSettings?.queries}
            onChange={(event) =>
              updateWidgetSettings({
                ...widgetSettings,
                queries: event.target.value.split(','),
              })
            }
          />
        </InputField>

        <Button type="submit" color={color}>
          Generera widget-kod
        </Button>
      </Form>

      {url && (
        <Result>
          {/*<label className="terms">*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    onChange={() => setTermsAgreed((termsAgreed) => !termsAgreed)}*/}
          {/*  />*/}
          {/*  Jag godkänner <a href="#">villkoren</a>*/}
          {/*</label>*/}
          {/* Add this to the div below className={termsAgreed ? '' : 'dimmer'} */}
          <div>
            <SyntaxHighlighter
              language="html"
              style={atomOneDark}
              wrapLines={true}
            >
              {generateSnippet(url)}
            </SyntaxHighlighter>

            <div className="tooltip">
              <Button
                color={color}
                onClick={() => {
                  const el = document.createElement('textarea');
                  el.value = generateSnippet(url);
                  el.setAttribute('readonly', '');
                  el.style.position = 'absolute';
                  el.style.left = '-9999px';
                  document.body.appendChild(el);
                  el.select();
                  document.execCommand('copy');
                  document.body.removeChild(el);
                  const tooltip = document.getElementById('myTooltip');
                  if (tooltip) {
                    tooltip.innerHTML = 'Kopierat!';
                  }
                }}
                // disabled={!termsAgreed}
              >
                <span className="tooltiptext" id="myTooltip">
                  Kopiera till urklipp
                </span>
                Kopiera
              </Button>
            </div>
          </div>
        </Result>
      )}
    </EditorContainer>
  );
};

const EditorContainer = styled.div`
  width: 50%;
  flex: 0 1 50%;
  background: #fff;
  border-left: 1px solid #dbdbdb;
  padding: 0 40px;

  & > .editor-header > h1 {
    font-size: 40px;
    font-weight: 600;
    margin: 40px auto 20px;
  }

  & > .editor-description {
    margin-bottom: 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const InputField = styled.div`
  flex: 0 1 calc(50% - 20px);
  margin-bottom: 20px;
  margin-right: 20px;

  label {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;

    & > p {
      font-weight: 400;
    }
  }

  &.radius {
    flex: 0 1 100px;
  }

  &.bgColor {
    flex: 1 1 25%;
    margin-right: 0;
  }

  &.header {
    flex: 0 1 100%;
  }
`;

const Input = styled.input`
  height: 38px;
  width: 100%;

  border: 1px solid #cbd5e0;
  font-size: 16px;
  text-indent: 10px;
`;

const ColorContainer = styled.div`
  position: absolute;
`;

interface IColorPreview {
  color: string;
}

const ColorPreview = styled.div<IColorPreview>`
  height: 38px;
  width: 100px;
  background: ${({ color }) => color};
`;

interface IButton {
  color: string;
}

const Button = styled.button<IButton>`
  // CSS reset
  margin: 20px 0 0 0;
  padding: 9px 15px;
  width: auto;
  overflow: visible;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  // Styles
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #6783ff;
  border: 1px solid;
  border-color: #6783ff;
  border-radius: 2px;
`;

const Result = styled.section`
  margin: 20px 0;

  & .terms {
    display: block;
    margin-bottom: 10px;

    & > input {
      margin-right: 10px;
    }
  }

  & .dimmer {
    filter: blur(2px);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  }
`;
