import React from 'react';
import styled from 'styled-components/macro';

interface ISuggestions {
  queries?: string[];
  onClick: (query: string) => void;
}

export const Suggestions: React.FC<ISuggestions> = ({ queries, onClick }) => (
  <SuggestionsContainer>
    <h3>Sökförslag</h3>
    <SuggestionsList>
      {queries?.map((query: string, i) => (
        <li key={i} onClick={() => onClick(query)}>
          {query}
        </li>
      ))}
    </SuggestionsList>
  </SuggestionsContainer>
);

export const SuggestionsContainer = styled.div`
  padding: 0 10px 10px;
  background: ${({ theme }) => theme.bgColor};
  color: #fff;

  & > h3 {
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
`;

export const SuggestionsList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  & > li {
    // Styles
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    text-align: center;

    color: #000;
    background: #fff;
    border: 1px solid #000;
    border-radius: 2px;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
      color: #000;
      background: #d7d7d9;
    }
  }
`;
